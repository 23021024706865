let changed = false;

window.onresize = () => {
  setTimeout(() => {
    const baseStyle =
      "font-size: 1.5rem; font-weight: bold; color: #fff; background-color: #000; padding: 10px; text-shadow: 2px 0 0 rgba(255, 0, 55, 0.856), -2px 0 0 rgb(0, 162, 255);";
    if (!changed) {
      console.log(
        "%c  В левой руке - ♂SEMEN♂.",
        `${baseStyle} border-radius: 10px 10px 0 0;`
      );
      console.log(
        "%c В правой руке - ♂BUCKS♂.",
        `${baseStyle} border-radius: 0 10px 0 0;`
      );
      console.log(
        "%c Мой ♂DUNGEON MASTER♂ - Карл Маркс.",
        `${baseStyle} border-radius: 0 10px 10px 10px`
      );
    }
    changed = true;
  }, 1000);
};

const svg = document.querySelector(".profile__sign");
let maxLength = 0;

for (const path of svg.querySelectorAll("path")) {
  const length = Math.ceil(path.getTotalLength());
  const lengthAttr = length.toString();
  path.setAttribute("stroke-dasharray", lengthAttr);
  path.setAttribute("stroke-dashoffset", lengthAttr);
  if (length > maxLength) maxLength = length;
}
svg.style.setProperty("--path-length", maxLength.toString());
let isBack = false;

const ava = document.querySelector("#profile__avatar");

function rotateBw() {
  ava.classList.replace("profile__avatar--front", "profile__avatar--back");
}

function rotateFw() {
  ava.classList.replace("profile__avatar--back", "profile__avatar--front");
}

function rotate() {
  if (isBack) {
    rotateFw();
  } else {
    rotateBw();
  }

  isBack = !isBack;
}
